export default {
  data() {
    return {
      headers: [],
      sortFiled: [],
      sortIsDesc: [],
      createComponent: {
        name: 'Adauga',
        component: 'createComponentNameValue'
      },
      storeName: ''
    }
  },
  methods: {
    beforeLoad() {
      return new Promise((resolve) => {
        resolve();
      })
    },
    openAddNew() {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: this.createComponent.component,
        title: this.createComponent.name
      })
    },
    editItem(item) {
      this.$store.dispatch(`${this.storeName}/editItem`, item)
    },
    changePage(newPage) {
      this.$store.dispatch(`${this.storeName}/changePage`, newPage)
    },
    updateSortField(field) {
      this.sortFiled = field
    },
    updateSortType(isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter(searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch(`${this.storeName}/deleteFilter`, filterName)
      } else {
        this.$store.dispatch(`${this.storeName}/addFilter`, {
          filterName,
          searchValue
        })
      }
    },
    loadItems() {
      this.$store.dispatch(`${this.storeName}/loadItems`)
    },
    deleteItem(item) {
      this.$store.dispatch(`${this.storeName}/deleteItem`, item.id)
    }
  },
  computed: {
    totalItems() {
      return this.paginatedItems.length
    },
    paginatedItems() {
      return this.$store.getters[`${this.storeName}/paginatedData`]
    },
    pagination() {
      return this.$store.getters[`${this.storeName}/pagination`]
    },
    currentPage() {
      return this.$store.getters[`${this.storeName}/currentPage`]
    },
    sortParam() {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  watch: {
    sortParam(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch(`${this.storeName}/changeSort`, newValue)
      }
    }
  },
  created() {
    this.beforeLoad().then(() => {
      this.loadItems()
    })
  }
}
